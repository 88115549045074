/* CSS Resets */
input::-ms-clear {
  display: none;
}

input[type='number']:focus,
input[type='number'] {
  -moz-user-select: text !important;
  -webkit-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

input[type='password']::-ms-reveal {
  display: none;
}

:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

a[href^='#']:after,
a[href^='javascript:']:after {
  content: '';
}

/* Loading Spinner  */
.loader {
  border: 16px solid #e2e1dd;
  border-radius: 50%;
  border-top: 16px solid #9a9b9c;
  border-bottom: 16px solid #9a9b9c;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Application Template Tile */

.clickable:hover {
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* Ace Editor style */
.ace_editor {
  border: solid 1px lightgrey;
}

/* Remove margin from end of forms */
form {
  margin-block-end: 0;
}

/* Global Styles */

html,
body {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

a,
a:link,
a:visited {
  text-decoration: none;
}
a:hover,
.motd a:hover {
  color: #67616d;
  outline: 0;
}

/* Needed for IE to display CCS Grid Layout */
.ms-grid {
  display: -ms-grid;
}

/*
  All <summary> tags should use the pointer cursor
  Hide the outline on the summary when you expand the details
*/
summary {
  cursor: pointer;
}

summary:focus {
  outline: none;
}
